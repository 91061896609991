// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-borneo-tsx": () => import("./../../../src/pages/borneo.tsx" /* webpackChunkName: "component---src-pages-borneo-tsx" */),
  "component---src-pages-bruxelles-tsx": () => import("./../../../src/pages/bruxelles.tsx" /* webpackChunkName: "component---src-pages-bruxelles-tsx" */),
  "component---src-pages-greece-tsx": () => import("./../../../src/pages/greece.tsx" /* webpackChunkName: "component---src-pages-greece-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-india-tsx": () => import("./../../../src/pages/india.tsx" /* webpackChunkName: "component---src-pages-india-tsx" */),
  "component---src-pages-israel-tsx": () => import("./../../../src/pages/israel.tsx" /* webpackChunkName: "component---src-pages-israel-tsx" */),
  "component---src-pages-nasa-image-of-the-day-tsx": () => import("./../../../src/pages/nasa-image-of-the-day.tsx" /* webpackChunkName: "component---src-pages-nasa-image-of-the-day-tsx" */),
  "component---src-pages-nepal-tsx": () => import("./../../../src/pages/nepal.tsx" /* webpackChunkName: "component---src-pages-nepal-tsx" */),
  "component---src-pages-new-zealand-tsx": () => import("./../../../src/pages/new-zealand.tsx" /* webpackChunkName: "component---src-pages-new-zealand-tsx" */),
  "component---src-pages-northern-territory-tsx": () => import("./../../../src/pages/northern-territory.tsx" /* webpackChunkName: "component---src-pages-northern-territory-tsx" */),
  "component---src-pages-scotland-tsx": () => import("./../../../src/pages/scotland.tsx" /* webpackChunkName: "component---src-pages-scotland-tsx" */),
  "component---src-pages-wedding-shooting-tsx": () => import("./../../../src/pages/wedding-shooting.tsx" /* webpackChunkName: "component---src-pages-wedding-shooting-tsx" */),
  "component---src-pages-western-australia-tsx": () => import("./../../../src/pages/western-australia.tsx" /* webpackChunkName: "component---src-pages-western-australia-tsx" */)
}

